<template>
  <footer class="py-5 mt-6 custom-footer footer-to-bottom">
    <div class="container p-5">
      <div class="columns">
        <div class="column footer-left">
          <div class="footer-company">
            <logo />
            <p class="footer-left__title my-5">
              ЕДИНАЯ ОНЛАЙН-ПЛАТФОРМА <br />ДЛЯ ЗАКУПОК
            </p>
            <div class="footer-left__copyright">
              © 2022 ООО "ЕВРАЗ СТИЛ БИЛДИНГ"
            </div>
          </div>
          <div class="footer-nav pl-5">
            <p class="footer-title mb-4">Навигация</p>
            <b-navbar>
              <template #start>
                <b-navbar-item href="/rules"> Регламент </b-navbar-item>
                <b-navbar-item href="/help"> Помощь </b-navbar-item>
                <b-navbar-item href="/contacts"> Контакты </b-navbar-item>
              </template>
            </b-navbar>
          </div>
        </div>
        <div class="column footer-center mb-6 pl-5">
          <p class="footer-title mb-4">Реквизиты</p>
          <div class="muted-text">
            ОГРН 1207700116414 <br>
            ИНН 9724008628 <br>
            КПП 772401001 <br><br>
            Расчетный счет 40702810538000132654 <br>в ПАО Сбербанк <br><br>
            Корреспондентский счет 30101810400000000225 <br><br>
            БИК 044525225
          </div>
        </div>
        <div class="column footer-right">
          <div class="footer-right__contacts">
            <p class="footer-title mb-5">НУЖНА ПОМОЩЬ?</p>
            <div class="footer-right__contact footer-right__phone">
              <a href="tel:84996474777">+7 499 647 47 77</a>
              <span class="muted-text">По вопросам сотрудничества и участия в тендерах</span>
            </div>
            <div class="footer-right__contact footer-right__email">
              <a href="mailto:e-tender@evraz.com">e-tender@evraz.com</a>
              <span class="muted-text">Электронная почта для поддержки</span>
            </div>
          </div>
          <div class="footer-right__buttons">
            <p class="footer-title mb-4">ДЛЯ УЧАСТНИКОВ</p>
            <div class="buttons" v-if="!isLoggedIn">
              <router-link
                to="/login"
                class="button is-normal border-button mb-5"
              >
                <span>Войти</span>
              </router-link>
              <router-link to="/sign-up" class="button is-normal main-button">
                <span class="icon is-small">
                  <user-add-icon />
                </span>
                <span>Регистрация</span>
              </router-link>
            </div>
            <div v-else>
              <router-link
                to="/profile"
                class="button is-normal border-button mb-5"
              >
                <span>Профиль</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "../icons/Logo.vue";
import UserAddIcon from "../icons/UserAddIcon.vue";
import authService from "../../services/auth.service";

export default {
  components: { Logo, UserAddIcon },
  name: "Footer",
  computed: {
    isLoggedIn() {
      return authService.isLoggedIn();
    },
  },
};
</script>
