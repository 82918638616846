<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.3335 13.333V14.6664H1.3335V13.333H9.3335ZM9.72416 0.457031L14.9095 5.64236L13.9668 6.58637L13.2602 6.35037L11.6088 7.9997L15.3802 11.771L14.4375 12.7137L10.6668 8.94237L9.06416 10.545L9.25283 11.2997L8.3095 12.2424L3.12416 7.05703L4.0675 6.11437L4.82083 6.30237L9.01683 2.10703L8.7815 1.40036L9.72416 0.457031ZM10.1955 2.81437L5.4815 7.5277L7.83816 9.88503L12.5522 5.1717L10.1955 2.81437Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'HammerIcon',
  props: {
    color: {
      type: String,
      default: '#323232'
    }
  }
}
</script>