<template>
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.226914 0.96959C0.36756 0.828987 0.558291 0.75 0.757164 0.75C0.956037 0.75 1.14677 0.828987 1.28741 0.96959L4.99991 4.68209L8.71241 0.96959C8.85387 0.832971 9.04332 0.757375 9.23997 0.759084C9.43661 0.760793 9.62472 0.83967 9.76378 0.978726C9.90283 1.11778 9.98171 1.30589 9.98342 1.50254C9.98513 1.69919 9.90953 1.88864 9.77291 2.03009L5.53016 6.27284C5.38952 6.41344 5.19879 6.49243 4.99991 6.49243C4.80104 6.49243 4.61031 6.41344 4.46966 6.27284L0.226914 2.03009C0.0863108 1.88944 0.00732422 1.69871 0.00732422 1.49984C0.00732422 1.30097 0.0863108 1.11024 0.226914 0.96959Z" fill="#6F6F6F"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon'
}
</script>