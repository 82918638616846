<template>
  <b-navbar class="authorized-menu">
    <template #brand>
      <router-link to="/" class="logo-item">
        <logo />
      </router-link>
    </template>

    <template #start>
      <header-top />
      <div class="navbar-left-menu">
        <b-navbar-item href="/tenders">
          <span class="icon">
            <hammer-icon />
          </span>
          Тендеры
        </b-navbar-item>
        <b-navbar-item href="/orders">
          <span class="icon">
            <box-icon />
          </span>
          Заявки
        </b-navbar-item>
      </div>
      <div class="navbar-right-menu">
        <b-navbar-item href="/rules"> Регламент </b-navbar-item>
        <b-navbar-item href="/help"> Помощь </b-navbar-item>
        <b-navbar-item href="/contacts"> Контакты </b-navbar-item>
      </div>
    </template>

    <template #end>
      <b-navbar-item tag="div" class="navbar-user-authorized mt-4-to-desktop">
        <div class="user-preview">
          <span>{{ userIndetials }}</span>
        </div>
        <div class="user-preview-right">
          <span class="user-preview-status">
            СТАТУС:
            <span class="user-preview-status-marker">проверен</span>
          </span>
          <span class="user-preview-name">{{ username }}</span>
        </div>
        <span class="user-preview-arrow">
          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger="{}">
              <span class="pl-3 pt-3 pb-3">
                <arrow-icon class="" />
              </span>
            </template>
            <b-dropdown-item href="/profile">
              <span>Профиль</span>
            </b-dropdown-item>

            <b-dropdown-item aria-role="listitem" @click="logout">
              <span>Выйти</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import Logo from "@/components/icons/Logo.vue";
import HeaderTop from "./HeaderTop.vue";
import HammerIcon from "../icons/HammerIcon.vue";
import BoxIcon from "../icons/BoxIcon.vue";
import ArrowIcon from "../icons/ArrowIcon.vue";
import authService from "../../services/auth.service";

import { EventBus } from "@/common/eventBus";

import store from "@/store/index";

export default {
  name: "Navbar",
  components: { Logo, HeaderTop, HammerIcon, BoxIcon, ArrowIcon },
  methods: {
    logout() {
      authService.logout();
    },
  },
  computed: {
    filterSelects() {
      return this.$store.getters["tender/list"];
    },
    username() {
      return authService.getUsername();
    },
    userIndetials() {
      const name = authService.getAuthorizedName();
      if (name) {
        const nameArray = name.split(' ')
        if (nameArray.length >= 2)
          return nameArray[0][0] + nameArray[1][0]
      }
      return ''
    }
  },
};

const tenderFiltersUpdatedHandler = function () {
  console.info(`tenderFiltersUpdatedHandler!`, store.getters["tender/list"]);
};

EventBus.$on("tenderFiltersUpdated", tenderFiltersUpdatedHandler);
</script>

<style lang="scss">
.authorized-menu a.logo-item {
  position: relative;
  padding: 0.5em 0.9em;
}

.authorized-menu a.navbar-item {
  position: relative;
  padding: 6px 13px;
  height: 4em;
  top: 1em;
  &:hover {
    .icon {
      svg path {
        fill: #a01b5b;
      }
    }
  }
  .icon {
    margin-right: 0.3em;
    * {
      transition: all 0.3s;
    }
  }
  &:after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0.3em;
    left: 0;
    transition: all 0.3s;
    background: #a2165b;
  }
  &:hover:after {
    width: 100%;
  }
}
.navbar-left-menu {
  display: flex;
}
.navbar-right-menu {
  display: flex;
  margin-left: 100px;
  position: relative;
  .navbar-item + .navbar-item {
    margin-left: 22px;
  }
  &::before {
    content: "";
    height: 28px;
    width: 1px;
    background: #dddddd;
    position: absolute;
    left: -50px;
    top: 1.25em;
  }
  &::after {
    content: "";
    height: 2.25em;
    width: 1px;
    background: #dddddd;
    position: absolute;
    right: -50px;
    top: 22px;
  }
}
.user-preview {
  width: 42px;
  height: 42px;
  color: #a2165b;
  border: 1px solid #a2165b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  line-height: 2.5em;
  text-transform: uppercase;
}
.user-preview-right {
  padding-left: 1.25em;
  .user-preview-status {
    color: #6f6f6f;
    font-size: 10px;
  }
  .user-preview-status-marker {
    background: linear-gradient(90deg, #2ecc71 0%, #27ae60 100%);
    border-radius: 3px;
    padding: 2px 0.3em;
    color: white;
  }
  .user-preview-name {
    display: block;
    color: #323232 !important;
  }
}

.navbar.authorized-menu .navbar-start {
  margin-right: 7.6em;
}

@media screen and (max-width: 1200px) {
  .navbar.authorized-menu .navbar-start {
    margin-right: 0;
  }
  .navbar-right-menu {
    margin-left: 60px;
    &::before {
      left: -22px;
    }
    &::after {
      display: none;
    }
    .navbar-item + .navbar-item {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .navbar-left-menu {
    display: block;
  }
  .navbar-right-menu {
    display: block;
    margin-left: 0;
  }
  .navbar-item.navbar-user-authorized {
    display: flex;
  }
}
</style>